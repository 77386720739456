import React, { useEffect, useState } from 'react';

import { Text } from '@chakra-ui/react';

import { Label } from '~/components/UI/Text';
import { IRadio, IText } from '~/components/UI/Inputs';
import { Button, Divider } from '~/components/ChakraComponents';
import TimeInput from 'react-time-input';
import api from '~/services/api';
import { toast } from 'react-toastify';

import './style.css';

const getHoursByDate = date => {
  date = new Date(date);
  let h = String(date.getHours());
  if (h.length === 1) {
    h = '0' + h;
  }
  let m = String(date.getMinutes());
  if (m.length === 1) {
    m = '0' + m;
  }
  return h + ':' + m;
};

export default function ChooseWorkedHours({
  value,
  setValue,
  vacancy,
  differentiatedValue,
  disableChooseWorkedHours,
  setDisableChooseWorkedHours,
  setLoading,
  checkinAllowed,
}) {
  const [proportionalValueOption, setProportionalValueOption] = useState(true);
  const [totalValueOption, setTotalValueOption] = useState(false);
  const [
    proportionalOvertimeValueOption,
    setProportionalOvertimeValueOption,
  ] = useState(false);
  const [paymentRuleSelected, setPaymentRuleSelected] = useState(
    'proportional'
  );
  const [proportionalValue, setProportionalValue] = useState('');
  const [realStartHour, setRealStartHour] = useState('');
  const [realEndHour, setRealEndHour] = useState('');

  useEffect(() => {
    if (vacancy.paymentValue) {
      setProportionalValue(vacancy.paymentValue);
    }
    if (vacancy.checkin && vacancy.checkinDate) {
      setRealStartHour(getHoursByDate(vacancy.checkinDate));
    }
    if (vacancy.checkout && vacancy.checkoutDate) {
      setRealEndHour(getHoursByDate(vacancy.checkoutDate));
    }
  }, []);

  const onFocusHandler = event => {
    return null;
  };

  const onBlurHandler = event => {
    return null;
  };

  const onTimeChangeRealStartHour = val => {
    setRealStartHour(val);
    const isProportionalOvertime =
      proportionalOvertimeValueOption && !proportionalValueOption;
    computeValueProportional(val, realEndHour, isProportionalOvertime);
  };

  const onTimeChangeRealEndHour = val => {
    setRealEndHour(val);
    const isProportionalOvertime =
      proportionalOvertimeValueOption && !proportionalValueOption;
    computeValueProportional(realStartHour, val, isProportionalOvertime);
  };

  const computeValueProportional = (sHour, eHour, overtime) => {
    if (sHour.length !== 5 || eHour.length !== 5) {
      return '';
    }

    function getHour(hourAndMinute) {
      return hourAndMinute.slice(0, 2);
    }

    function getMinute(hourAndMinute) {
      return hourAndMinute.slice(3, 5);
    }

    const _startHour = new Date(vacancy.startHour);
    const _endHour = new Date(vacancy.endHour);

    const _realStartHour = new Date(_startHour);
    _realStartHour.setHours(getHour(sHour), getMinute(sHour), 0);

    const _realEndHour = new Date(_endHour);
    _realEndHour.setHours(getHour(eHour), getMinute(eHour), 0);

    // Verifica se os horários finais são menores que os iniciais e ajusta se necessário
    if (_realEndHour < _realStartHour) {
      _realStartHour.setDate(_realStartHour.getDate() - 1);
    }
    if (_endHour < _startHour) {
      _startHour.setDate(_startHour.getDate() - 1);
    }

    // Ajuste de horas se overtime for falso
    if (!overtime) {
      if (_realStartHour < _startHour) {
        _realStartHour.setTime(_startHour.getTime());
      }
      if (_realEndHour > _endHour) {
        _realEndHour.setTime(_endHour.getTime());
      }
    }

    const realTime = _realEndHour - _realStartHour;
    const planTime = _endHour - _startHour;

    let percentageTime = realTime / planTime;

    if (!overtime && percentageTime > 1) {
      percentageTime = 1;
    }

    const proportionalValue = percentageTime * value;
    setProportionalValue(proportionalValue.toFixed(2));
  };

  const saveWorkerHours = () => {
    function formatDate(data) {
      setLoading(true);

      var dia = data.getDate();
      if (dia.toString().length == 1) {
        dia = '0' + dia;
      }
      var mes = data.getMonth() + 1;
      if (mes.toString().length == 1) {
        mes = '0' + mes;
      }
      var ano = data.getFullYear();

      return ano + '-' + mes + '-' + dia;
    }

    const url = `/companies/myhospitals/schedules/vacancies/${vacancy.id}/realhours`;

    function getHour(hourAndMinute) {
      return hourAndMinute.slice(0, 2);
    }
    function getMinute(hourAndMinute) {
      return hourAndMinute.slice(3, 5);
    }

    const _realStartHour = new Date();
    _realStartHour.setHours(
      getHour(realStartHour),
      getMinute(realStartHour),
      0
    );

    const startHourFormatted =
      formatDate(_realStartHour) + ' ' + _realStartHour.toLocaleTimeString();

    let _realEndHour = false;
    let realEndHourFormatted = '';

    if (realEndHour) {
      _realEndHour = new Date();
      _realEndHour.setHours(getHour(realEndHour), getMinute(realEndHour), 0);

      realEndHourFormatted =
        formatDate(_realEndHour) + ' ' + _realEndHour.toLocaleTimeString();
    }

    const body = {
      realStartHour: startHourFormatted,
      realEndHour: realEndHourFormatted,
    };

    if (paymentRuleSelected) {
      body.paymentRule = paymentRuleSelected;
    }

    api
      .patch(url, body)
      .then(response => {
        toast.success('Plantão editado com sucesso!');
        setLoading(false);
        setDisableChooseWorkedHours(true);
      })
      .catch(error => {
        toast.error('Ocorreu algum erro ao editar plantão!');
        setLoading(false);
      });
  };

  const setOption = option => {
    setPaymentRuleSelected(option);
    if (option === 'proportional') {
      setTotalValueOption(false);
      setProportionalValueOption(true);
      setProportionalOvertimeValueOption(false);
      computeValueProportional(realStartHour, realEndHour, false);
    }
    if (option === 'total') {
      setTotalValueOption(true);
      setProportionalValueOption(false);
      setProportionalOvertimeValueOption(false);
    }
    if (option === 'proportional-overtime') {
      setTotalValueOption(false);
      setProportionalValueOption(false);
      setProportionalOvertimeValueOption(true);
      computeValueProportional(realStartHour, realEndHour, true);
    }
  };

  useEffect(() => {
    if (vacancy.paymentRule) {
      setOption(vacancy.paymentRule);
    }
  }, []);

  useEffect(() => {
    const isProportionalOvertime =
      proportionalOvertimeValueOption && !proportionalValueOption;
    computeValueProportional(
      realStartHour,
      realEndHour,
      isProportionalOvertime
    );
  }, [value, paymentRuleSelected]);

  return (
    <>
      <Label>Medição de presença</Label>
      {checkinAllowed ? (
        <>
          <div className="block-time-inputs">
            <div className="block-time-input">
              <label>Horario de entrada do médico</label>
              <TimeInput
                name="realStartHour"
                initTime={realStartHour}
                className="time-input"
                mountFocus={false}
                disabled={disableChooseWorkedHours}
                onTimeChange={onTimeChangeRealStartHour}
                onFocusHandler={onFocusHandler}
                onBlurHandler={onBlurHandler}
              />
            </div>
            <div className="block-time-input">
              <label>Horario de saída do médico</label>
              <TimeInput
                name="realEndHour"
                initTime={realEndHour}
                className="time-input"
                mountFocus={false}
                disabled={!realStartHour || disableChooseWorkedHours}
                onTimeChange={onTimeChangeRealEndHour}
                onFocusHandler={onFocusHandler}
                onBlurHandler={onBlurHandler}
              />
            </div>
            <div className="block-time-payments">
              <Divider />
              <Label marginTop="6px" marginBottom="10px">
                Regra de Pagamento
              </Label>
              <div className="block-time-payment-options">
                <div className="block-time-proportional-payment">
                  <IRadio
                    label="Proporcional com limite"
                    checked={proportionalValueOption}
                    disabled={disableChooseWorkedHours}
                    onChange={e => setOption('proportional')}
                  />
                  <IRadio
                    label="Proporcional sem limite"
                    checked={proportionalOvertimeValueOption}
                    disabled={disableChooseWorkedHours}
                    onChange={e => setOption('proportional-overtime')}
                  />
                </div>
                <IRadio
                  label="Integral"
                  checked={totalValueOption}
                  disabled={disableChooseWorkedHours}
                  onChange={e => setOption('total')}
                />
              </div>
              <div className="block-time-payment-info">
                {proportionalValueOption ? (
                  <div>
                    <IText
                      useCleave="true"
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: 'thousand',
                      }}
                      style={{ width: '125px' }}
                      placeholder="Editar plantão executado"
                      value={proportionalValue}
                      disabled={true}
                      onChange={e =>
                        setProportionalValue(e.target.value.replace(',', ''))
                      }
                    />
                  </div>
                ) : totalValueOption ? (
                  <div>
                    <IText
                      useCleave="true"
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: 'thousand',
                      }}
                      style={{ width: '125px' }}
                      placeholder="Insira os horários"
                      value={value}
                      disabled={true}
                      // onChange={e => setValue(e.target.value.replace(',', ''))}
                    />
                  </div>
                ) : proportionalOvertimeValueOption ? (
                  <IText
                    useCleave="true"
                    options={{
                      numeral: true,
                      numeralThousandsGroupStyle: 'thousand',
                    }}
                    style={{ width: '125px' }}
                    placeholder="Editar plantão executado"
                    value={proportionalValue}
                    disabled={true}
                    onChange={e =>
                      setProportionalValue(e.target.value.replace(',', ''))
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="block-time-submit">
                <Button
                  title={
                    disableChooseWorkedHours
                      ? 'Editar Plantão Executado'
                      : 'Salvar Alterações'
                  }
                  variant={disableChooseWorkedHours ? 'secondary' : 'success'}
                  onClick={() => {
                    if (disableChooseWorkedHours) {
                      setDisableChooseWorkedHours(false);
                    } else {
                      saveWorkerHours();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Text
          userSelect="none"
          fontWeight="semibold"
          color={'var(--light-gray)'}
        >
          * Somente é possivel registro de horas a partir de 5 minutos antes do
          horário de inicio do plantão.
        </Text>
      )}
    </>
  );
}
